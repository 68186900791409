.Image {
  width: 50vw;
}
.card {
  width: 500px;
  height: 400px;
  border-radius: 10px;
}
.cardTitle {
  color: white;
  margin: 20px auto;
}

.button {
  margin-top: 30px;
}
