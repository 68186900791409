.Image {
  width: 50vw;
}
.card {
  width: 500px;
  height: max-content;
  border-radius: 10px;
}
.cardTitle {
  color: black;
  margin: 0px auto 20px auto;
}

.button {
  margin-top: 30px;
}
