@media (max-width: 480px) {
  .Image {
    width: 90vw;
  }

  .Title {
    font-size: 30px;
    line-height: 30px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .Image {
    width: 60vw;
  }
  .Title {
    font-size: 40px;
    line-height: 40px;
  }
}

@media (min-width: 769px) and (max-width: 1023px) {
  .Image {
    width: 60vw;
  }
  .Title {
    font-size: 50px;
    line-height: 50px;
  }
}

@media (min-width: 1024px) {
  .Image {
    width: 50vw;
  }
  .Title {
    font-size: 70px;
    line-height: 70px;
  }
}
