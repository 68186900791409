.text-center {
  text-align: center !important;
}

.marginTop-10px {
  margin-top: 10px;
}

.width-100 {
  width: 100% !important;
}

.width-88 {
  width: 88%;
}
.padding20 {
  padding: 40px 20px;
}

.maxWidth-20rem {
  max-width: 20rem;
}

.rs-picker-toggle-wrapper {
  width: 100%;
  max-width: 20rem;
}

.fontSize-13px {
  font-size: 13px;
}

.zIndex-100 {
  z-index: 100;
}

.center-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.width-20rem {
  width: 20rem;
}

.fontClick {
  font-size: 14px;
  font-weight: 600;
  color: #5867dd;
  cursor: pointer;
  font-style: italic;
}

.inputPicker {
  width: 100%;
  max-width: 100%;
}

#Input {
  max-width: 100%;
}

.panel-minHeight {
  background: #fff;
  min-height: 75vh;
}

.maxWidth-100 {
  max-width: 100%;
}

.distribution-item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.flex-container {
  display: flex;
}

.width-50 {
  width: 50%;
}

.padding-left-5rem {
  padding-left: 5rem;
}

.dashboard-body {
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  text-align: center;
}

.main-logo {
  max-width: 100%;
  width: 700px;
  text-align: center;
  margin-bottom: 100px;
}

.separateInfoLicense {
  margin-bottom: 12px;
}

.validar-licencia-logos {
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  width: 100%;

  align-items: center;
  justify-content: center;
}

.validar-licencia-logoIFPI,
.validar-licencia-logoMusicartes {
  width: min(50%, 100px);
}

.validar-licencia-logoAginpro {
  width: min(50%, 400px);
}

.color-white {
  color: #fff;
  gap: 20px;
}

.validar-licencia-mt {
  margin-top: 30px;
}

.validar-licencia-mb {
  margin-bottom: 50px;
}
.contenedor {
  margin: 13px !important;
}
/*
.table-responsive {
  max-height: 500px !important;
  overflow-y: auto;
}

.table-core-responsive {
  max-height: 500px !important;
  overflow-y: auto;
  position: relative;
}

.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(192, 192, 192, 0.3) !important;
  border-radius: 10px !important;
  background-color: #dfdfdf !important;
}

.table-responsive::-webkit-scrollbar {
  width: 8px !important;
  height: 8px !important;
  background-color: #f5f5f5 !important;
}

.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(219, 219, 219, 0.3) !important;
  background-color: #555 !important;
}

.table-core-head {
  background: #fff;
  z-index: 1;
  position: sticky;
  top: 0;
  left: 0;
} */

.icon-button-notify {
  position: relative;
}

.icon-button-notify-badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  background: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.rs-notification-content {
  max-width: 100% !important;
}

@media (max-width: 800px) {
  .validar-licencia-logos {
    gap: 10px;
  }
  .validar-licencia-logoIFPI,
  .validar-licencia-logoMusicartes {
    width: min(15%, 100px);
  }

  .validar-licencia-logoAginpro {
    width: min(60%, 400px);
  }
}
